<template>
  <section class="page-news bg-gray-100 min-h-screen">
    <div
      v-if="initializing"
      class="flex flex-col justify-center items-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <div class="flex flex-col w-screen min-h-screen">
        <van-sticky>
          <van-search
            v-model="form.keyword"
            show-action
            label="搜索"
            placeholder="请输入搜索关键字"
            :disabled="refreshing"
            @search="doSearch"
          >
            <template #action>
              <div @click="doSearch">
                搜索
              </div>
            </template>
          </van-search>
          <van-dropdown-menu class="conditions-container border-b-1 border-t-1">
            <van-dropdown-item
              v-model="form.status"
              :options="statuses"
              @change="doSearch"
            />

            <van-dropdown-item
              v-model="form.year"
              :options="years"
              @change="doSearch"
            />
          </van-dropdown-menu>
        </van-sticky>
        <van-pull-refresh
          v-if="loading || news.total"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="news.to === null || news.to == news.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="pb-12"
            @load="fetch"
          >
            <div
              v-for="item of news.data"
              :key="`item-${item._id}`"
              class="
                list-card
                bg-white
                border-gray-200 border-b
                p-3
                m-3
                rounded-lg
              "
              @click="
                $router.push({
                  name: 'workspace.news.edit',
                  params: { news: item._id }
                })
              "
            >
              <div class="flex flex-row justify-start items-center">
                <div
                  v-if="item.image"
                  class="thumbnail"
                >
                  <van-image
                    width="80"
                    height="80"
                    :src="`${item.image}!square_head_image`"
                  />
                </div>
                <div
                  class="
                    content
                    flex-1
                    pl-2
                    flex flex-col
                    justify-between
                    items-start
                  "
                  :style="item.image ? 'min-height: 80px;' : ''"
                >
                  <div class="font-semibold leading-snug mb-2">
                    {{ item.title
                    }}<van-tag
                      v-if="item.onTop"
                      round
                      type="danger"
                      class="ml-1"
                    >
                      置顶
                    </van-tag>
                    <van-tag
                      plain
                      :type="
                        newsStatus[item.status]
                          ? newsStatus[item.status]
                          : 'primary'
                      "
                      class="ml-1"
                    >
                      {{ item.status }}
                    </van-tag>
                  </div>
                  <div
                    class="
                      text-sm text-gray-400
                      flex flex-row
                      justify-between
                      items-center
                      w-full
                    "
                  >
                    <span><van-icon name="newspaper-o" /> {{ item.category }}</span>
                    <span><van-icon name="calendar-o" />
                      {{ $dayjs(item.publishedAt).format('YYYY-MM-DD') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
        <van-empty
          v-if="!loading && news.total == 0"
          description="暂无相关社区新闻"
        >
          <a
            href="#"
            class="text-red-500"
            @click="reset"
          >清除过滤条件</a>
        </van-empty>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'WorkspaceNews',
  mixins: [share],
  data() {
    return {
      loading: true,
      initializing: true,
      refreshing: false,
      form: {
        status: '',
        year: '',
        keyword: ''
      },
      news: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Config', ['newsStatus']),
    statuses() {
      return [{ text: '全部状态', value: '' }].concat(
        Object.keys(this.newsStatus).map(item => ({
          text: item,
          value: item
        }))
      )
    },
    years() {
      let years =  [{ text: '全部时间', value: '' }]
      let year = new Date().getFullYear()
      for(let i = year;i >= 2021; i--) {
        years.push({ text: `${i}年`, value: i })
      }
      return years
    }
  },
  mounted() {
    this.fetch()
    this.disableShareMenu()
  },
  methods: {
    async doSearch() {
      this.initializing = true
      let query = Object.assign({}, this.form, {
        _timestamp: +new Date()
      })

      await this.$router.push({ name: 'workspace.news', query })
      this.onRefresh()
    },
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.news = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let query =
        Object.keys(this.$route.query).length > 0
          ? this.$route.query
          : { status: '', building: '', source: '', keyword: '' }
      // eslint-disable-next-line no-console
      console.log('查询信息', query)
      this.form = Object.assign({ year: '', keyword: '', status: '' }, query)
      let page = (this.news.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'news',
          data: Object.assign(
            {
              $url: 'workspaceList',
              page: page
            },
            this.form
          )
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        if (result.code == 401) {
          this.$router.replace({ name: 'errors.401' })
          return
        }
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let news = {
          data: this.news.data.concat(result.data.data),
          to: this.news.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', news)
        this.$set(this, 'news', news)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取意见和建议列表' })
        this.news = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    reset() {
      this.form = {
        status: '',
        year: '',
        keyword: ''
      }
      this.doSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
::v-deep .conditions-container {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }
}
::v-deep .van-search--show-action {
  padding-right: 12px;
}
</style>
